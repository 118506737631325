<template>
  <div>
    <div class="mb-2">
      <b-button variant="primary-custom" @click="$router.push('/empresas')">
        <fa-icon icon="arrow-left" class="mr-2"></fa-icon> Voltar
      </b-button>
    </div>
    <TableList :data="applicationsProducts" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      unityId: this.$route.params.unityId,
    };
  },
  components: {
    TableList: () => import("./components/TableList"),
  },
  computed: {
    applicationsProducts() {
      return this.$store.state.unities.applicationsProducts;
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "unities/applications_products_init",
      this.unityId
    );
    console.log(this.applicationsProducts);
  },
};
</script>

<style>
</style>